import TypingEffect from '../fadeInComponent/fade-in-component'
import { FolioContact } from '../folio-contact/folio-contact'
import { FolioFooter } from '../folio-footer/folio-footer'
import { FolioWork } from '../folio-work/folio-work'
import './folio-about.css'
export function FolioAbout(){
    return(
        <div className="about">
            
         {/* <h1>Welcome to about</h1> */}
         <div className="content-wrapper-about gap-4">
                <h1 className="text-uppercase">Mern Stack Developer</h1>
                <h3>Bring Your Dreams to Life</h3>
                <h1><TypingEffect /></h1>
                <button className="btn btn-danger">Latest Work</button>
                <span className='bi-chevron-down'></span>
            </div>
           <div><FolioWork/></div>
          <div><FolioContact/></div>
          <div><FolioFooter/></div>
            
        </div>
    )
}