import './folio-footer.css';
export function FolioFooter() {
    return (
        <div>
            <div className='d-flex justify-content-between text-white fw-bold p-4'>
                <div className="left-footer">

                </div>
                <div className=" fs-5 text-center center-footer text-center">
                    © 2024 Rajendra Kushwaha
                    <div className=" fs-3 right-footer text-center">
                        <div>
                            <a href='https://www.linkedin.com/in/rajendra-kushwaha-8584a8271/' target='_blank' >
                                <span className="bi bi-linkedin text-white me-4"></span></a>

                            <a href='https://www.instagram.com/rajendrakushwaha1732/' target='_blank' >
                                <span className="bi bi-instagram text-white me-4"></span></a>

                            <a href='mailto:rajendra.k619@gmail.com' target='_blank' >
                                <span className="bi bi-envelope text-white me-4"></span></a>

                            <a href='https://github.com/RajendraKumarKushwaha' target='_blank' >
                                <span className="bi bi-github text-white me-4"></span></a>
                        </div>
                    </div>
                </div>
                <div></div>


            </div>
        </div>
    )
}