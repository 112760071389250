import { useState } from 'react';
import './folio-index.css';
import  {FolioHome}  from '../folio-home/folio-home';
import { FolioAbout } from '../folio-about/folio-about';
import { FolioContact } from '../folio-contact/folio-contact';

export function FolioIndex() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <div className="">
            <div className="d-flex justify-content-between align-items-center text-white  bg-header">
                <div>
                    <h3>Rajendra Kumar Kushwaha</h3>
                </div>

                {/* Toggle Button (Only visible on smaller screens) */}
                <button 
                    className="btn text-white d-md-none" 
                    onClick={toggleMenu}
                    aria-label="Toggle Menu"
                >
                    ☰
                </button>

                {/* Navigation Menu */}
                <div className={`sidebar-menu ${isMenuOpen ? 'show' : ''} d-md-flex gap-3 fw-bold`}>
                    <div className='bi bi-laptop-fill'> Work</div>
                    <div className='bi bi-person-fill'> About</div>
                    <div className='bi bi-envelope-fill'> Contact</div>
                    
                   
                </div>
            </div>
           <div>
           <FolioHome/>
           </div>
            <div>
            <FolioAbout/>
            </div>
           
        </div>
    );
}
