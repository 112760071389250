import React, { useEffect, useRef } from "react";
import Typed from "typed.js";

const TypingEffect = () => {
  // Create a reference to attach Typed.js to a DOM element
  const typedRef = useRef(null);

  useEffect(() => {
    // Options for the Typed instance
    const options = {
      strings: ["Frontend Developer! ", "Mern Stack Developer!"],
      typeSpeed: 50,
      backSpeed: 30,
      loop: true,
    };

    // Initialize Typed.js on the element referenced by `typedRef`
    const typed = new Typed(typedRef.current, options);

    // Cleanup function to destroy the Typed instance on component unmount
    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <div>
      <span ref={typedRef}></span>
    </div>
  );
};

export default TypingEffect;
