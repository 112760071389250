import './folio-contact.css';
export function FolioContact() {
    return (
        <div className="mt-5">
            <h1 className="text-white text-center">Contact Us</h1>
            <div className="contact d-flex justify-content-between m-4">
                <div className="left ">
                    <img src="images/contact.png" alt="" className='left-img' />
                </div>
                <div className="right">
                    <form className="border border-white rounded-2 p-3">
                        <dl className=" text-white fs-5 p-2">
                            <dt className="form-label bi bi-person"> Name</dt>
                            <dd><input type="text" className="form-control w-100" /></dd>
                            <dt className="form-label bi bi-at"> Email</dt>
                            <dd><input type="text" className="form-control w-100" /></dd>
                            <dt className="form-label bi bi-envelope "> Message</dt>
                            <dd> <textarea className="form-control w-100" rows="5"></textarea></dd>
                        </dl>
                        <div className="text-center mt-3">
                            <button className="btn btn-primary">Send</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}