import TypingEffect from '../fadeInComponent/fade-in-component';
import './foilo-home.css';

export function FolioHome() {
    return (
        <div className="bg-image">
           <div className='bg-color'>

           </div>
        </div>
    );
}
