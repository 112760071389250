import { FolioContact } from '../folio-contact/folio-contact';
import './folio-work.css';
export function FolioWork() {
    return (
        <div>
            <div className="d-flex flex-column justify-content-center align-items-center">
                <div>
                    <button id='#work' className="btn btn-dark">Latest Work</button>
                </div>
                <div className='d-flex justify-content-center align-items-center'>
                    <div className="verticl-line position-absolute">
                    </div>
                    <div className='horizontal-left position-relative'>
                    </div>
                    <div className='circle'>
                    </div>
                </div>
            </div>
            {/* Memories world */}
            <div className='memories-world d-flex'>
                <div className='laptop' style={{ color: "#c8cad3" }}>
                    <a href="https://memoriesworld.vercel.app/" target="_blank" rel="noopener noreferrer">
                        <img className='laptop-img' src='/images/memoriesback.png' alt='laptop' />
                    </a>
                </div>
                <div className='about-memories '>
                    <div className='bg-memories'>
                        <h2 className='fw-bold'>Memories World</h2>
                        <p className='fs-5'>(Photography Studio)</p>

                    </div>
                    <p className='fw-bold memories-para'>Designed and developed the website from scratch. visually captivating and responsive website for Memories World Photography Studio, a premier photography
                        studio specializing in capturing life’s most precious moments.</p>

                    <ul className=' memories-list list-unstyled d-flex flex-wrap gap-3'>
                        <li className='border border-1 rounded-5 p-2'>#react.js</li>
                        <li className='border border-1 rounded-5 p-2'>#bootstrap</li>
                        <li className='border border-1 rounded-5 p-2'>#html</li>
                        <li className='border border-1 rounded-5 p-2'>#css</li>
                        <li className='border border-1 rounded-5 p-2'>#responsive design</li>
                    </ul>

                </div>
            </div>


            {/* EV FIX */}
            <div className='d-flex justify-content-center align-items-center'>
                <div className=" position-absolute">
                </div>
                <div className='horizontal-right position-relative'>
                </div>
                <div className='circle-evfix'>
                </div>
            </div>
            <div className='evfix d-flex justify-content-end align-content-right'>

                <div className='about-evfix '>
                    <div className='bg-evfix'>
                        <h2 className='fw-bold'>EVFIX GARAZ</h2>
                        <p className='fs-5'>(Find & Service Electric Vehicle Garages with Ease)</p>

                    </div>
                    <p className='fw-bold evfix-para'>I contributed to enhancing the user experience for EVFix Garaz,
                         a platform connecting users with electric vehicle service garages.
                          My work focused on refining the website’s visual appeal and usability by implementing 
                          strategic updates to the color scheme, content layout, and imagery. These modifications
                           were tailored to improve readability, engagement, and brand consistency, ultimately 
                           elevating the platform's user interface for a more seamless and appealing experience.
                    </p>

                    <ul className='evfix-list list-unstyled d-flex flex-wrap gap-3'>
                        <li className='border border-1 rounded-5 p-2'>#react.js</li>
                        <li className='border border-1 rounded-5 p-2'>#html</li>
                        <li className='border border-1 rounded-5 p-2'>#css</li>
                        <li className='border border-1 rounded-5 p-2'>#javascript</li>
                        <li className='border border-1 rounded-5 p-2'>#bootstrap</li>
                    </ul>

                </div>
                <div className='evfix-laptop text-end' style={{ color: "#c8cad3" }}>
                <a href="https://evfix.vercel.app/" target="_blank" rel="noopener noreferrer">
                        <img className='laptop-img' src='/images/evfix.png' alt='laptop' />
                    </a>
                </div>
            </div>



            {/* Prime Bazaar */}

            <div className='d-flex justify-content-center align-items-center'>
                <div className="verticl-line position-absolute">
                </div>
                <div className='horizontal-left position-relative'>
                </div>
                <div className='circle-prime'>
                </div>
            </div>
            <div className='prime-bazaar d-flex justify-content-end align-content-right'>
                <div className='prime-laptop' style={{ color: "#c8cad3" }}>
                <a href="https://prime-bazaar.vercel.app/" target="_blank" rel="noopener noreferrer">
                        <img className='laptop-img' src='/images/primebazaar2.png' alt='laptop' />
                    </a>
                </div>
                <div className='about-prime '>
                    <div className='bg-prime'>
                        <h2 className='fw-bold'>Prime Bazaar</h2>
                        <p className='fs-5'>( Full-Stack Online Shopping Platform)</p>

                    </div>
                    <p className='fw-bold prime-para'>Built from the ground up, Prime Bazaar is a feature-rich online shopping platform leveraging the MERN stack.
                         It includes dynamic product listings fetched via an external API, seamless payment processing powered by
                          Stripe, and state management implemented with Redux for efficient add-to-cart functionality.
                           The platform demonstrates a commitment to clean architecture, scalable design, 
                           and a user-friendly shopping experience, ensuring high performance and reliability.
                    </p>

                    <ul className='prime-list list-unstyled d-flex flex-wrap gap-3'>
                        <li className='border border-1 rounded-5 p-2'>#react.js</li>
                        <li className='border border-1 rounded-5 p-2'>#express.js</li>
                        <li className='border border-1 rounded-5 p-2'>#node.js</li>
                        <li className='border border-1 rounded-5 p-2'>#redux.js</li>
                        <li className='border border-1 rounded-5 p-2'>#mongoDB</li>
                        <li className='border border-1 rounded-5 p-2'>#stripe payment gateway</li>
                        <li className='border border-1 rounded-5 p-2'>#html</li>
                        <li className='border border-1 rounded-5 p-2'>#css</li>
                        <li className='border border-1 rounded-5 p-2'>#javascript</li>
                        <li className='border border-1 rounded-5 p-2'>#bootstrap</li>
                    </ul>

                </div>

            </div>

            {/* VistaHive */}
            
             <div className='d-flex justify-content-center align-items-center'>
                <div className=" position-absolute">
                </div>
                <div className='horizontal-right vista-right position-relative'>
                </div>
                <div className='circle-vista'>
                </div>
            </div>
            <div className='evfix d-flex justify-content-end align-content-right'>

                <div className='about-evfix '>
                    <div className='bg-vista'>
                        <h2 className='fw-bold'>VistaHive</h2>
                        <p className='fs-5'>(Online Shopping Platform)</p>

                    </div>
                    <p className='fw-bold vista-para'>Developed entirely from scratch, VistaHive is an online shopping project that delivers a seamless shopping experience.
                    I integrated an external API to dynamically fetch product listings, enhancing the site’s scalability.
                    This project showcases my expertise in frontend and backend development, efficient API handling, and user-focused design. 
                    VistaHive provides users with a smooth, intuitive interface and robust functionality, from product browsing to Add to Cart.
                    </p>

                    <ul className='vista-list list-unstyled d-flex flex-wrap gap-3'>
                        <li className='border border-1 rounded-5 p-2'>#react.js</li>
                        <li className='border border-1 rounded-5 p-2'>#html</li>
                        <li className='border border-1 rounded-5 p-2'>#css</li>
                        <li className='border border-1 rounded-5 p-2'>#javascript</li>
                        <li className='border border-1 rounded-5 p-2'>#bootstrap</li>
                    </ul>

                </div>
                <div className='evfix-laptop text-end' style={{ color: "#c8cad3" }}>
                <a href="https://vista-hive.vercel.app/" target="_blank" rel="noopener noreferrer">
                        <img className='laptop-img' src='/images/vistahive.png' alt='laptop' />
                    </a>
                </div>
            </div>
        </div>
    )
}